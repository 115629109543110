<template>
  <v-row>
    <v-col cols="12" md="6">
      <form-field-date-picker
        :value="obInvoiceUI.date"
        :hint="$t('invoice.ui.start.date')"
        :min="min"
        :max="max"
        persistent-hint
        @change="onUpdate"
      />
    </v-col>
    <v-col cols="12" md="6">
      <form-field-text
        :value="obInvoiceUI.value"
        label="value"
        input-type="number"
        @input:debounce="onUpdate"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";

export interface MaxInvoicesUI {
  date: string;
  value: number;
}

@Component({ components: { FormFieldDatePicker } })
export default class MaxInvoiceUI extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obInvoiceUI!: MaxInvoicesUI;
  @Prop(String) readonly min!: string;
  @Prop(String) readonly max!: string;

  onUpdate(sValue: string | number) {
    const obData = this.$_.clone(this.obInvoiceUI);
    if (this.$_.isNumber(sValue)) {
      obData.value = sValue;
    } else {
      obData.date = sValue;
    }

    this.$emit("input", obData);
  }
}
</script>
